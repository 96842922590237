@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.order-violation-modal {
  background-color: white;
  text-align: center;
  padding: 20px;
  border-radius: $border-radius-default;

  &__title {
    font-size: rem(20);
    font-weight: 500;
  }

  &__message {
    font-size: rem(18);
    font-weight: 300;
    line-height: 22px;
  }

  &__button {
    width: 100%;

    @include mq('mobile-l') {
      width: auto;
    }
  }
}
